import React from 'react';
import Button from '../components/Button';

const VerifyEmail = () => {
  return (
    <div>
      <h1 className="l-mb--2">Step Verification</h1>
      <p className="l-mb--2">You need to verify your email to continue. Please check your email.</p>
      {/* @TODO Wire up this button */}
      <Button>Resend</Button>
    </div>
  );
};

export default VerifyEmail;

import React from 'react';
import Loader from './../components/Loader';
import { useUserContext } from '../contexts/UserContextProvider';
import CompanyProfile from '../components/CompanyProfile';

const Dashboard = () => {
  const { user } = useUserContext();

  if (!user) {
    return <Loader />;
  }

  return <CompanyProfile company={user?.associatedcompany} />;
};

export default Dashboard;

import React from 'react';
import classNames from 'classnames';
import styles from '../../styles/components/inputs/Label.module.scss';

type LabelTypes = {
  htmlFor: string;
  children: string;
  className?: string;
};

const Label = ({ className, htmlFor, children }: LabelTypes): JSX.Element => (
  <label className={classNames([styles['label'], className])} htmlFor={htmlFor}>
    {children}
  </label>
);

export default Label;

import React, { useEffect, useMemo, useState } from 'react';
import Loader from './Loader';
import Button from './Button';
import Table from './Table';
import { useApi } from '../contexts/ApiProvider';
import { Company } from '../services/Api';

const CompanyList = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();
  const { api } = useApi();

  useEffect(() => {
    if (api) {
      const getCompanies = async () => {
        const apiResult = await api.companies();
        if (apiResult.hasError) {
          setError(apiResult.messages?.join(', '));
        } else if (apiResult.records) {
          setCompanies(apiResult.records);
        }
        setIsLoading(false);
      };
      getCompanies();
    }
  }, [setCompanies, setIsLoading, api]);

  const updateCompanyEnrollment = async (company: Company, status: 'decline' | 'approve') => {
    if (api) {
      const apiResult = await api.updateCompanyEnrollment(company.identityProviderId, status);
      if (apiResult.hasError) {
        setError(apiResult.messages?.join(', '));
      } else if (apiResult.record) {
        const updated_companies = [...companies];
        const updated_company = updated_companies.find(item => item.identityProviderId === company.identityProviderId);
        if (updated_company) {
          updated_company.portalApproved = apiResult.record.portalApproved;
        }
        setCompanies(updated_companies);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: 'name',
      },
      {
        Header: 'TYPE',
        accessor: 'type',
      },
      {
        Header: 'STATUS',
        accessor: 'status',
      },
      {
        Header: '',
        accessor: 'action',
      },
    ],
    []
  );

  const data = companies.map(company => {
    return {
      name: company.name,
      type: company.type,
      status: company.portalApproved,
      action:
        company.portalApproved === 'approved' ? (
          <Button onClick={() => updateCompanyEnrollment(company, 'decline')}>Decline</Button>
        ) : (
          <Button onClick={() => updateCompanyEnrollment(company, 'approve')}>Approve</Button>
        ),
    };
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <h2>Companies</h2>
      {error && <p className="error error--visible">{error}</p>}
      <Table columns={columns} data={data} />
    </div>
  );
};

export default CompanyList;

import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import styles from '../styles/components/Navigation.module.scss';
import Icon from './Icon';

const Navigation = () => {
  const location = useLocation();

  return (
    <nav className={styles['nav']}>
      <ul className={styles['nav-list']}>
        <li className={styles['nav-item']}>
          <NavLink
            className={classNames([
              styles['nav-link'],
              location.pathname === '/companies' && styles['nav-link--active'],
            ])}
            to="/companies"
          >
            <Icon
              name="office"
              size="small"
              className="l-mr--1"
              color={location.pathname === '/companies' ? 'white' : 'secondary'}
              displayInline
            />
            Companies
          </NavLink>
        </li>
        <li className={styles['nav-item']}>
          <NavLink
            className={classNames([
              styles['nav-link'],
              location.pathname === '/contacts' && styles['nav-link--active'],
            ])}
            to="/contacts"
          >
            <Icon
              size="small"
              name="group"
              className="l-mr--1"
              color={location.pathname === '/contacts' ? 'white' : 'secondary'}
              displayInline
            />
            Contacts
          </NavLink>
        </li>
        <li className={styles['nav-item']}>
          <NavLink
            className={classNames([styles['nav-link'], location.pathname === '/audits' && styles['nav-link--active']])}
            to="/audits"
          >
            <Icon
              size="small"
              name="shield"
              className="l-mr--1"
              color={location.pathname === '/audits' ? 'white' : 'secondary'}
              displayInline
            />
            Audits
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;

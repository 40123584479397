import React from 'react';

const Loader = ({ fullScreen }: { fullScreen?: boolean }) => {
  return (
    <div
      style={fullScreen ? { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' } : {}}
    >
      <p>Loading...</p>
    </div>
  );
};

export default Loader;

import React, { useEffect, useMemo, useState } from 'react';
import Loader from './Loader';
import Button from './Button';
import Table from './Table';
import { useApi } from '../contexts/ApiProvider';
import { Company, Contact } from '../services/Api';

type MemberListProps = {
  company?: Company;
  companyId?: string;
};

const MemberList = ({ company, companyId }: MemberListProps) => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const { api } = useApi();

  useEffect(() => {
    const id = companyId || company?.id;
    if (id && api) {
      const getCompanyContacts = async () => {
        const apiResult = await api.companyContacts(id);
        if (apiResult.hasError) {
          setError(apiResult.messages?.join(', '));
        } else if (apiResult.records) {
          setContacts(apiResult.records);
        }
        setIsLoading(false);
      };
      getCompanyContacts();
    }
  }, [companyId, company, setContacts, api, setIsLoading]);

  const updateContactEnrollment = async (contact: Contact, status: 'decline' | 'approve') => {
    const id = companyId || company?.id;
    if (id && api) {
      const apiResult = await api.updateContactEnrollment(id, contact.identityProviderId, status);
      if (apiResult.hasError) {
        setError(apiResult.messages?.join(', '));
      } else if (apiResult.record) {
        const updated_contacts = [...contacts];
        const updated_contact = updated_contacts.find(item => item.identityProviderId === contact.identityProviderId);
        if (updated_contact) {
          updated_contact.portalApproved = apiResult.record.portalApproved;
        }
        setContacts(updated_contacts);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: 'name',
      },
      {
        Header: 'EMAIL',
        accessor: 'email',
      },
      {
        Header: 'STATUS',
        accessor: 'status',
      },
      {
        Header: '',
        accessor: 'action',
      },
    ],
    []
  );

  const data = contacts.map(contact => {
    return {
      name: `${contact.firstname} ${contact.lastname}`,
      email: contact.email,
      status: contact.portalApproved,
      action:
        contact.portalApproved === 'approved' ? (
          <Button onClick={() => updateContactEnrollment(contact, 'decline')}>Decline</Button>
        ) : (
          <Button onClick={() => updateContactEnrollment(contact, 'approve')}>Approve</Button>
        ),
    };
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <h2>Members</h2>
      {error && <p className="error error--visible">{error}</p>}

      <Table columns={columns} data={data} />
    </div>
  );
};

export default MemberList;

import React from 'react';

type FlexColumnTypes = {
  grow?: number;
  shrink?: number;
  basis?: string;
  alignSelf?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode | React.ReactChildren;
};

const FlexColumn = ({
  grow = 1,
  shrink = 1,
  basis = 'auto',
  alignSelf = 'auto',
  style,
  children,
}: FlexColumnTypes): JSX.Element => (
  <div
    className="flex__column"
    style={{
      flex: `${grow} ${shrink} ${basis}`,
      alignSelf: alignSelf, // eslint-disable-line
      ...style,
    }}
  >
    {children}
  </div>
);

export default FlexColumn;

import React from 'react';
import UserContextProvider from './contexts/UserContextProvider';
import ApiProvider from './contexts/ApiProvider';
import { Auth0Provider } from '@auth0/auth0-react';
import Routes from './Routes';
import './app.scss';

const App = () => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line
    const ReactDOM = require('react-dom');
    // eslint-disable-next-line
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
  }

  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN || ''}
      clientId={process.env.AUTH0_CLIENT || ''}
      audience={process.env.AUTH0_AUDIENCE || ''}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <ApiProvider>
        <UserContextProvider>
          <Routes />
        </UserContextProvider>
      </ApiProvider>
    </Auth0Provider>
  );
};

export default App;

import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Header from './Header';
import { useAuth0 } from '@auth0/auth0-react';
import styles from '../styles/components/Layout.module.scss';
import Loader from './Loader';

const Layout = () => {
  const location = useLocation();
  const alternateLayout = location.pathname.includes('enroll') || location.pathname.includes('login');
  const { isLoading, isAuthenticated } = useAuth0();

  return (
    <div className={classNames([styles['layout'], alternateLayout && styles['layout--alt']])}>
      {isLoading ? (
        <main id="main">
          <Loader fullScreen />
        </main>
      ) : (
        <>
          <Header alternateLayout={alternateLayout || !isAuthenticated} />
          <main id="main">
            <Outlet />
          </main>
        </>
      )}
    </div>
  );
};

export default Layout;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContextProvider';
import Loader from './Loader';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isEnrolled, isLoading, isAuthenticated, authUser } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        if (!authUser.email_verified) {
          navigate('/enroll/verify_email');
        } else if (!isEnrolled) {
          // Display Enrollment Form
          navigate('/enroll/setup');
        }
      } else {
        navigate('/login');
      }
    }
  }, [isLoading, isAuthenticated, isEnrolled]); // eslint-disable-line

  if (isLoading) {
    return <Loader />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;

import React from 'react';
import classNames from 'classnames';
import style from '../../styles/components/inputs/Textbox.module.scss';

type TextBoxTypes = {
  // placeholder message
  placeholder: string;
  // unique id for textbox
  id: string;
  // disable or enable textbox
  disabled?: boolean;
  // text displayed for textbox
  value: string | number;
  // optional class styling override of default textbox
  className?: string;
  // listener for onChange of textbox
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // optional regex for input element
  pattern?: string;
};

const SimpleTextBox = (props: TextBoxTypes): JSX.Element => {
  const { placeholder, id, className, disabled, onChange, value, pattern } = props;
  const inputClass = classNames([
    style['form-textbox-input'],
    !!value.toString().length && style['form-textbox-entered'],
  ]);
  return (
    <div className={classNames([style['form-textbox'], className && className])}>
      <input
        id={id}
        aria-labelledby={`${id}_label`}
        pattern={pattern}
        className={inputClass}
        disabled={disabled}
        onChange={onChange}
        value={value}
      />
      <label className={style['form-textbox-label']} htmlFor={id}>
        {placeholder}
      </label>
    </div>
  );
};

SimpleTextBox.defaultProps = {
  className: '',
  pattern: '.*',
};

export default SimpleTextBox;

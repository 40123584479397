import React, { useEffect, useState, createContext, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Api from '../services/Api';
import { ApiClient } from '../services/Api';

type IApiContext = {
  api?: ApiClient | null;
};

const ApiContext = createContext<IApiContext>({
  api: null,
});

const useApi = () => useContext(ApiContext);

const ApiProvider = ({ children }: { children: React.ReactNode }) => {
  const [api, setApi] = useState<ApiClient>();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setApi(Api(getAccessTokenSilently));
  }, [getAccessTokenSilently]);

  return (
    <ApiContext.Provider
      value={{
        api: api,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export { useApi };
export default ApiProvider;

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from '../styles/components/Button.module.scss';

type Props = {
  id: string;
  children: React.ReactNode;
  element: string;
  type: string;
  disabled: boolean;
  href: string;
  target: string;
  onClick: () => void;
  tabIndex: string;
  color: 'primary' | 'secondary' | 'tertiary' | 'white' | 'transparent';
  collapsed: boolean;
  hidden: boolean;
  download: boolean;
  to: string;
  ariaLabel?: string;
  className: string;
  childClassName: string;
  variant?: 'solid' | 'outline' | 'collapsed';
  inline?: boolean;
};

const Button = ({
  tabIndex,
  type,
  hidden,
  ariaLabel,
  download,
  id,
  to,
  element,
  href,
  onClick,
  disabled,
  target,
  children,
  color,
  variant,
  inline,
  className,
  childClassName,
}: Props) => {
  const classes = [
    styles['button'],
    color && styles[`button--${color}`],
    variant && styles[`button--${variant}`],
    inline && styles['button--inline'],
    className,
  ];

  return element === 'Link' ? (
    <Link id={id} className={classNames(classes)} to={to} onClick={onClick} aria-label={ariaLabel}>
      {children}
    </Link>
  ) : (
    React.createElement(
      element,
      {
        id: id,
        className: classNames(classes),
        href: href,
        target: target,
        rel: 'noopener noreferrer',
        onClick: onClick,
        disabled: disabled,
        tabIndex: tabIndex,
        type: type,
        hidden: hidden,
        'aria-label': ariaLabel,
        download: download,
      },
      React.createElement('span', { className: childClassName }, children)
    )
  );
};

Button.defaultProps = {
  id: null,
  className: '',
  childClassName: '',
  element: 'button',
  type: 'button',
  disabled: false,
  href: null,
  target: null,
  onClick: null,
  tabIndex: '0',
  color: 'primary',
  collapsed: false,
  hidden: false,
  download: false,
  to: null,
};

export default Button;

import React from 'react';
import classNames from 'classnames';
import FlexColumn from './FlexColumn';
import style from '../../styles/components/Flex.module.scss';

type FlexProps = {
  children: React.ReactNode | React.ReactChildren;
  addedStyle?: React.CSSProperties;
  wrap?: boolean;
  direction?: string;
  reversed?: boolean;
  alignment?: string;
  justify?: string;
  className?: string;
};

const Flex = ({
  wrap = false,
  direction = 'row',
  addedStyle,
  reversed,
  alignment,
  justify,
  className,
  children,
}: FlexProps): JSX.Element => {
  const classes = [
    style.flex,
    wrap && style['flex--wrap'],
    reversed && style['flex--reversed'],
    direction && style[`flex--${direction}`],
    alignment && style[`flex--align-${alignment}`],
    justify && style[`flex--justify-${justify}`],
    className && className,
  ];

  return (
    <div className={classNames(classes)} style={addedStyle}>
      {children}
    </div>
  );
};

Flex.Row = Flex;
Flex.Column = FlexColumn;

export default Flex;

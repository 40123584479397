import React, { useEffect, useState } from 'react';
import Loader from './Loader';
import { useApi } from '../contexts/ApiProvider';
import { Company } from '../services/Api';

type CompanyInfoProps = {
  company?: Company;
  companyId?: string;
};

const CompanyInfo = ({ company, companyId }: CompanyInfoProps) => {
  const [loadedCompany, setLoadedCompany] = useState<Company | undefined>(company);
  const [error, setError] = useState<string>();
  const { api } = useApi();

  useEffect(() => {
    if (companyId && api) {
      const getCompany = async () => {
        const apiResult = await api.company(companyId);
        if (apiResult.hasError) {
          setError(apiResult.messages?.join(', '));
        } else {
          setLoadedCompany(apiResult.record);
        }
      };
      getCompany();
    }
  }, [companyId, setLoadedCompany, api]);

  if (!loadedCompany) {
    return <Loader />;
  }

  return (
    <div>
      <h2>Company Info</h2>
      <h3>{loadedCompany.name}</h3>
      <p>
        {loadedCompany.address}
        <br />
        {loadedCompany.address2}
        <br />
        {loadedCompany.city}, {loadedCompany.state} {loadedCompany.zip}
      </p>
      <p>{loadedCompany.domain}</p>
      {error && <p className="error error--visible">{error}</p>}
    </div>
  );
};

export default CompanyInfo;

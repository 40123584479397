import React from 'react';
import classNames from 'classnames';
import Label from './Label';
import styles from '../../styles/components/inputs/Input.module.scss';

type InputTypes = {
  id: string;
  value: string | number;
  labelText: string;
  labelHidden?: boolean;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorText?: string;
  onClick?: (event: React.MouseEvent) => void;
};

const Input = ({
  placeholder = '',
  id,
  className,
  disabled = false,
  onChange,
  onClick,
  value,
  labelText,
  labelHidden,
  errorText,
}: InputTypes): JSX.Element => (
  <>
    <Label htmlFor={id} className={classNames(labelHidden && 'sr-only')}>
      {labelText}
    </Label>
    <input
      id={id}
      aria-labelledby={`${id}_label`}
      className={classNames(styles['input'], className)}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      onClick={onClick}
      value={value}
    />
    {errorText && <span className="error-message">{errorText}</span>}
  </>
);

export default Input;

import React from 'react';

const Caret = () => {
  return (
    <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(4, 0)" fillRule="nonzero">
        <path
          fill="currentColor"
          d="M15.5512422,13.8198758 L4.99223602,24.378882 C4.26242236,25.1086957 3.08229814,25.1086957 2.36024845,24.378882 L0.605590062,22.6242236 C-0.124223602,21.8944099 -0.124223602,20.7142857 0.605590062,19.992236 L8.09006211,12.507764 L0.605590062,5.02329193 C-0.124223602,4.29347826 -0.124223602,3.11335404 0.605590062,2.39130435 L2.35248447,0.621118012 C3.08229814,-0.108695652 4.26242236,-0.108695652 4.98447205,0.621118012 L15.5434783,11.1801242 C16.2810559,11.9099379 16.2810559,13.0900621 15.5512422,13.8198758 L15.5512422,13.8198758 Z"
        />
      </g>
    </svg>
  );
};

export default Caret;

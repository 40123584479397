import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from '../styles/components/Icon.module.scss';

type IconTypes = {
  name: string;
  size?: 'x-small' | 'small' | 'large' | 'x-large';
  rotate?: 0 | 45 | 90 | 180 | 270;
  color?:
    | 'black'
    | 'white'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'status--success'
    | 'status--warning'
    | 'status--error'
    | 'current-color';
  className?: string;
  displayInline?: boolean;
};

// To add additional icons, use https://heroicons.com/
// and add them to the /src/components/icons directory
// Icons must be formatted in a 24px x 24px viewBox without
// a set height and width and they also need `fill='currentColor'`
// to take on the color of the class name that styles it

const Icon = (props: IconTypes) => {
  const [isMounted, setIsMounted] = useState(false);
  const [icon, setIcon] = useState('');

  useEffect(() => {
    // This article details webpack magic comments used in dynamic import
    // https://medium.com/front-end-hacking/webpack-and-dynamic-imports-doing-it-right-72549ff49234

    // isMounted is an antipattern but solves the problem for this case
    // https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
    setIsMounted(true);
    const iconPath = `./icons/${props.name}`;
    import(/* webpackMode: "eager" */ `${iconPath}`)
      .then(module => {
        return module.default();
      })
      .then(IconComponent => {
        if (isMounted) {
          setIcon(IconComponent);
        }
      })
      .catch(() => {
        console.warn(`ICON NOT FOUND - ${props.name}`);
      });

    return function cleanup() {
      setIsMounted(false);
    };
  }, [isMounted, props.name]);

  const classes = [
    styles['icon'],
    props.size && styles[`icon--${props.size}`],
    props.color && styles[`icon--${props.color}`],
    props.rotate && styles[`icon--rotate-${props.rotate}`],
    props.displayInline && styles['icon--inline'],
    props.className,
  ];

  return <span className={classNames(classes)}>{icon}</span>;
};

Icon.defaultProps = {
  size: null,
  rotate: null,
  color: null,
  class: null,
};

export default Icon;

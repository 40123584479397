import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../components/Loader';

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {isAuthenticated && (
        <>
          <img src={user?.picture} alt={user?.name} />
          <h2>{user?.name}</h2>
          <p>{user?.email}</p>
        </>
      )}
    </div>
  );
};

export default Profile;

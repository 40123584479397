import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import Flex from '../components/layout/Flex';
import { useUserContext } from '../contexts/UserContextProvider';

const Login = () => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const { isAuthenticated } = useUserContext();
  const location = useLocation();

  const handleLogin = (showRegister = false) => {
    if (isAuthenticated) {
      navigate('/');
    } else {
      loginWithRedirect({ returnTo: location.pathname, screen_hint: showRegister ? 'signup' : undefined });
    }
  };

  return (
    <div className="constrained-width">
      <h1 className="l-pb--3">Welcome to CDSA!</h1>
      <p className="l-pb--2">You need to log in or register to continue</p>
      <Flex>
        <Button variant="outline" className="l-mr--1" onClick={() => handleLogin(true)}>
          Register
        </Button>
        <Button onClick={() => handleLogin(false)}>Log in</Button>
      </Flex>
    </div>
  );
};

export default Login;

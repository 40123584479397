import React from 'react';
import Flex from './../components/layout/Flex';
import PageHeader from '../components/PageHeader';
import CompanyInfo from '../components/CompanyInfo';
import MemberList from '../components/MemberList';
import { Company } from '../services/Api';

const CompanyProfile = ({ company }: { company: Company }) => {
  return (
    <div className="w-100">
      <PageHeader title={company.name} />
      <section>
        <Flex>
          <Flex.Column>
            <MemberList company={company} />
          </Flex.Column>
          <Flex.Column>
            <CompanyInfo company={company} />
          </Flex.Column>
        </Flex>
      </section>
    </div>
  );
};

export default CompanyProfile;

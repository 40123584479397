import React from 'react';
import classNames from 'classnames';
import Label from './Label';
import style from '../../styles/components/inputs/Select.module.scss';

type SelectTypes = {
  id: string;
  selected: string;
  options: string[] | { id: string; text: string }[] | any;
  labelText: string;
  labelHidden?: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  selectClassName?: string;
  className?: string;
  disabled?: boolean;
};

type OptionType = {
  option: { id: string; text: string } | string;
};

const Select = ({
  id,
  selected,
  options,
  labelText,
  className,
  selectClassName,
  onChange,
  disabled,
  labelHidden,
}: SelectTypes): JSX.Element => {

  console.log({ selected });

  const renderOption = (option: OptionType, index: number) => {


    if (typeof option === 'string') {
      return (
        <option value={option} aria-selected={selected === option} key={`option-${index}`}>
          {option}
        </option>
      );
    }
    // @ts-ignore
    console.log("option.id", option.id);

    return (

      // @ts-ignore
      <option data-id={option.id} value={option.id} aria-selected={selected === option.id} key={`option-${index}`}>
        {/* @ts-ignore */}
        {option.text}
      </option>
    );
  };

  const renderOptions = () => {
    if (Array.isArray(options)) {
      return options.map((option: OptionType, i: number) => renderOption(option, i));
    } else {
      return Object.keys(options).map((optionGroup, i) => {
        return (
          <optgroup label={optionGroup} key={`group-${i}`}>
            {options[optionGroup].map((option: OptionType, j: number) => renderOption(option, j))}
          </optgroup>
        );
      });
    }
  };

  return (
    <>
      <Label htmlFor={id} className={classNames(labelHidden && 'sr-only')}>
        {labelText}
      </Label>
      <div className={classNames([style['select-wrapper'], className && className])}>
        <select
          id={id}
          className={classNames([style['select'], selectClassName])}
          value={selected}
          onChange={onChange}
          // onBlur={onChange}
          disabled={disabled}
        >
          {renderOptions()}
        </select>
      </div>
    </>
  );
};

Select.defaultProps = {
  className: '',
  typography: '',
  disabled: false,
};

export default Select;

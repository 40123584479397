import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';

export type Contact = {
  id: string;
  authorizationProviderId: string;
  identityProviderId: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  associatedcompanyid: number;
  associatedcompany: Company;
  portalApproved: string;
};

export type Company = {
  id: string;
  identityProviderId: string;
  name: string;
  domain: string;
  portalApproved: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  type: string;
};

export type EnrollmentParams = {
  id?: string;
  name?: string;
  type?: string;
};

export type ApiResult<T> = {
  record?: T;
  records?: Array<T>;
  hasError: boolean;
  statusCode?: number;
  messages?: string[];
};

export type ApiClient = {
  currentContact: () => Promise<ApiResult<Contact>>;
  companies: (filters?: Array<string[]>) => Promise<ApiResult<Company>>;
  enroll: (company: EnrollmentParams) => Promise<ApiResult<Contact>>;
  company: (companyId: string) => Promise<ApiResult<Company>>;
  companyContacts: (companyId: string) => Promise<ApiResult<Contact>>;
  updateContactEnrollment: (
    companyId: string,
    contactIdentityProviderId: string,
    status: 'approve' | 'decline'
  ) => Promise<ApiResult<Contact>>;
  updateCompanyEnrollment: (
    companyIdentityProviderId: string,
    status: 'approve' | 'decline'
  ) => Promise<ApiResult<Contact>>;
};

const doGet = async <T,>(path: string, config: AxiosRequestConfig): Promise<ApiResult<T>> => {
  return axios
    .get(`/api/v1${path}`, config)
    .then((response: AxiosResponse) => {
      if (Array.isArray(response.data.data)) {
        return {
          records: response.data.data as Array<T>,
          statusCode: response.status,
          hasError: false,
        };
      } else {
        return {
          record: response.data.data as T,
          statusCode: response.status,
          hasError: false,
        };
      }
    })
    .catch((ex: Error | AxiosError) => {
      console.error(`Error in Api.doGet(${path})`, ex);
      if (axios.isAxiosError(ex)) {
        console.error(ex.response);
        return {
          hasError: true,
          statusCode: ex.response?.status,
          messages: ex.response?.data['errors'],
        };
      } else {
        return {
          hasError: true,
          messages: [ex.message],
        };
      }
    });
};

const doPost = async <T,>(path: string, data: any, config: AxiosRequestConfig): Promise<ApiResult<T>> => {
  return axios
    .post(`/api/v1${path}`, data, config)
    .then((response: AxiosResponse) => {
      return {
        record: response.data.data as T,
        statusCode: response.status,
        hasError: false,
      };
    })
    .catch((ex: Error | AxiosError) => {
      console.error(`Error in Api.doPost(${path})`, ex);
      if (axios.isAxiosError(ex)) {
        console.error(ex.response);
        return {
          hasError: true,
          statusCode: ex.response?.status,
          messages: ex.response?.data['errors'],
        };
      } else {
        return {
          hasError: true,
          messages: [ex.message],
        };
      }
    });
};

const doPut = async <T,>(path: string, data: any, config: AxiosRequestConfig): Promise<ApiResult<T>> => {
  return axios
    .put(`/api/v1${path}`, data, config)
    .then((response: AxiosResponse) => {
      return {
        record: response.data.data as T,
        statusCode: response.status,
        hasError: false,
      };
    })
    .catch((ex: Error | AxiosError) => {
      console.error(`Error in Api.doPut(${path})`, ex);
      if (axios.isAxiosError(ex)) {
        console.error(ex.response);
        return {
          hasError: true,
          statusCode: ex.response?.status,
          messages: ex.response?.data['errors'],
        };
      } else {
        return {
          hasError: true,
          messages: [ex.message],
        };
      }
    });
};

const getConfig = async (getToken: () => Promise<string>): Promise<AxiosRequestConfig> => {
  return {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${await getToken()}`,
    },
  };
};

const Api = (getToken: () => Promise<string>): ApiClient => {
  const currentContact = async (): Promise<ApiResult<Contact>> => {
    const config = await getConfig(getToken);
    return await doGet<Contact>('/contacts/current', config);
  };

  const fetchCompanies = async (filters?: Array<string[]>): Promise<ApiResult<Company>> => {
    const config = await getConfig(getToken);
    let query = '';
    if (filters && filters.length) {
      query = `?query=${JSON.stringify(filters)}`;
    }
    return await doGet<Company>(`/companies${query}`, config);
  };

  const enroll = async (company: EnrollmentParams): Promise<ApiResult<Contact>> => {
    const config = await getConfig(getToken);
    return await doPost<Contact>(`/enroll`, company, config);
  };

  const fetchCompany = async (companyId: string): Promise<ApiResult<Company>> => {
    const config = await getConfig(getToken);
    return await doGet<Company>(`/companies/${companyId}`, config);
  };

  const fetchCompanyContacts = async (companyId: string): Promise<ApiResult<Contact>> => {
    const config = await getConfig(getToken);
    return await doGet<Contact>(`/companies/${companyId}/contacts`, config);
  };

  const updateContactEnrollment = async (
    companyId: string,
    contactIdentityProviderId: string,
    status: 'approve' | 'decline'
  ): Promise<ApiResult<Contact>> => {
    const config = await getConfig(getToken);
    return await doPut<Contact>(`/companies/${companyId}/contacts/${contactIdentityProviderId}/${status}`, {}, config);
  };

  const updateCompanyEnrollment = async (
    companyIdentityProviderId: string,
    status: 'approve' | 'decline'
  ): Promise<ApiResult<Contact>> => {
    const config = await getConfig(getToken);
    return await doPut<Contact>(`/companies/${companyIdentityProviderId}/${status}`, {}, config);
  };

  return {
    currentContact: currentContact,
    companies: fetchCompanies,
    enroll: enroll,
    company: fetchCompany,
    companyContacts: fetchCompanyContacts,
    updateContactEnrollment: updateContactEnrollment,
    updateCompanyEnrollment: updateCompanyEnrollment,
  };
};

export default Api;

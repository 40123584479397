import React from 'react';

const StatusContent = ({ title, message }: { title: string; message: string }) => {
  return (
    <>
      <h2>{title}</h2>
      <p>{message}</p>
    </>
  );
};

export default StatusContent;

import React, { useState } from 'react';
import { useUserContext } from '../contexts/UserContextProvider';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useMedia } from 'react-use';
import Navigation from './Navigation';
import { Image } from './Image';
import Icon from './Icon';
import Divider from './Divider';
import CDSALogo from '../assets/images/cdsa-logo-white.png';
import styles from '../styles/components/Header.module.scss';
import navStyles from '../styles/components/Navigation.module.scss';
import Button from './Button';

const Header = ({ alternateLayout }: { alternateLayout: boolean }): JSX.Element => {
  const { isAuthenticated, user } = useUserContext();
  const isSmall = useMedia('(max-width: 700px)');
  const [isActive, setIsActive] = useState(false);

  const renderUserName = () => {
    if (!user?.firstname) {
      return user?.email;
    }
    return `${user?.firstname} ${user?.lastname}`;
  };

  return (
    <>
      <header
        className={classNames([
          styles['header'],
          isSmall && styles['header--small'],
          (isActive || alternateLayout || !isSmall) && styles['header--small--active'],
          alternateLayout && styles['header--alt'],
        ])}
      >
        {/* Render hambuger for mobile */}
        {isSmall && !alternateLayout && (
          <Button
            className={styles['header-menu-button']}
            color="white"
            onClick={() => setIsActive(!isActive)}
            collapsed
          >
            <span className="sr-only">Toggle menu</span>
            {isActive ? (
              <Icon className="l-mr--1" size="small" name="close" />
            ) : (
              <Icon className="l-mr--1" size="small" name="menu" />
            )}
          </Button>
        )}
        <div className={styles['header-layout']}>
          <NavLink to="/" end className={classNames([styles['header-logo'], !alternateLayout && 'l-pt--3'])}>
            <span className="sr-only">Dashboard</span>
            <Image src={CDSALogo} alt="CDSA logo" height={alternateLayout ? '31px' : '36px'} />
          </NavLink>
          {/* Render hr and skip link */}
          {!alternateLayout ? (
            <>
              <Divider className="l-mt--3" />
              <a className="sr-only" href="#main">
                Skip to content
              </a>
              <Navigation />
            </>
          ) : (
            isAuthenticated && (
              // Render horizontal header logout button
              <NavLink
                className={classNames([
                  navStyles['nav-link'],
                  navStyles['nav-link--collapsed'],
                  navStyles['nav-link--auto-width'],
                  'text--secondary',
                ])}
                to="/log-out"
              >
                <Icon className="l-mr--1" size="small" name="logout" color="secondary" displayInline />
                Log Out
              </NavLink>
            )
          )}
        </div>
        {/* Render bottom nav for profile and logout */}
        {!alternateLayout && isAuthenticated && (
          <div>
            <Divider />
            <NavLink className={navStyles['nav-link']} to="/profile">
              <div>
                <p className="text--white">{renderUserName()}</p>
                <p>{user?.associatedcompany.name}</p>
              </div>
            </NavLink>
            <NavLink className={classNames([navStyles['nav-link'], 'text--secondary'])} to="/log-out">
              <Icon className="l-mr--1" size="small" name="logout" color="secondary" displayInline />
              Log Out
            </NavLink>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;

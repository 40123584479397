import React, { useEffect, useState } from 'react';
import Input from '../inputs/Input';
import Select from '../inputs/Select';
import { Company } from '../../services/Api';
import { EnrollmentParams } from '../../services/Api';
import { useApi } from '../../contexts/ApiProvider';
import { useUserContext } from '../../contexts/UserContextProvider';
import Button from '../Button';

const EnrollmentAddToOrg = ({
  stepComplete,
}: {
  stepComplete: (isComplete: boolean, company: EnrollmentParams) => void;
}) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Company>();
  const [showNewOrgForm, setShowNewOrgForm] = useState(false);
  const [newOrgName, setNewOrgName] = useState('');
  const [newOrgType, setNewOrgType] = useState('Auditor');
  const { api } = useApi();
  const { authUser } = useUserContext();

  useEffect(() => {
    if (api && authUser) {
      const userDomain = authUser.email.split('@')[1];
      const getCompanies = async () => {
        const apiResult = await api.companies([['portal_approved', 'eq', 'approved']]);
        if (!apiResult.hasError && apiResult.records) {
          setCompanies(apiResult.records);
          const preselected = apiResult.records.find(item => item.domain === userDomain);
          if (preselected) {
            setSelectedCompany(preselected);
          }
        }
      };

      getCompanies();
    }
  }, [api, setCompanies, setSelectedCompany, authUser]);

  useEffect(() => {
    stepComplete(selectedCompany !== null, { id: selectedCompany?.id });
  }, [selectedCompany]); // eslint-disable-line

  useEffect(() => {
    stepComplete(newOrgName.length > 0 && newOrgType.length > 0, { name: newOrgName, type: newOrgType });
  }, [newOrgName, newOrgType]); // eslint-disable-line

  const handleCompanySelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCompany = event.target.value;

    if (selectedCompany === newOrgOption.id) {
      setSelectedCompany(undefined);
      setShowNewOrgForm(true);
    } else {
      const foundCompany = companies.find(company => company.id === selectedCompany);
      setSelectedCompany(foundCompany);
    }
  };

  const companyOptions = companies.map(company => ({ id: company.id, text: company.name }));
  const newOrgOption = { id: 'new', text: '+ Create New Organization' };

  return (
    <>
      {!showNewOrgForm ? (
        <>
          <h1 className="h4 l-mb--1">What is your organization?</h1>
          <Select
            id="company"
            labelText="What is your organization?"
            labelHidden
            onChange={handleCompanySelect}
            selected={selectedCompany?.id || ''}
            options={[{ id: '', text: 'Choose a company...' }, ...companyOptions, newOrgOption]}
          />
        </>
      ) : (
        <>
          <h1 className="h4 l-mb--2">Apply for a new organization profile</h1>
          <Input labelText="Organization" id="name" value={newOrgName} onChange={e => setNewOrgName(e.target.value)} />
          <Select
            id="type"
            selected={newOrgType}
            options={[
              { id: 'Auditor', text: 'Auditor' },
              { id: 'VENDOR', text: 'Vendor' },
              { id: 'Content Owner', text: 'Content Owner' },
            ]}
            onChange={e => setNewOrgType(e.target.value)}
            labelText="Type"
          />
          <Button variant="outline" onClick={() => setShowNewOrgForm(false)}>Go Back</Button>
        </>
      )}
    </>
  );
};

export default EnrollmentAddToOrg;

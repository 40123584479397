import React, { useEffect, useState } from 'react';
import EnrollmentAddToOrg from '../components/forms/EnrollmentAddToOrg';
import StatusContent from '../components/StatusContent';
import Loader from '../components/Loader';
import Button from '../components/Button';
import { EnrollmentParams } from '../services/Api';
import { useApi } from '../contexts/ApiProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserContext } from '../contexts/UserContextProvider';
import { useNavigate } from 'react-router-dom';
import Flex from '../components/layout/Flex';

enum Step {
  Init = 0,
  Form,
  Pending,
  PendingCompany,
  UserDenied,
  CompanyDenied,
}

const Enrollment = () => {
  const [step, setStep] = useState(Step.Init);
  const [enrollParams, setEnrollParams] = useState<EnrollmentParams>({});
  const [canNext, setCanNext] = useState(false);
  const [error, setError] = useState<string>();
  const { api } = useApi();
  const { user, isLoading } = useUserContext();
  const { logout } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (!user) {
        setStep(Step.Form);
      } else {
        switch (user.portalApproved) {
          case 'pending_company':
            if (user.associatedcompany.portalApproved === 'not_approved') {
              setStep(Step.CompanyDenied);
            } else {
              setStep(Step.PendingCompany);
            }
            break;
          case 'not_approved':
            setStep(Step.UserDenied);
            break;
          default:
            setStep(Step.Pending);
        }
      }
    }
  }, [user, setStep, isLoading]);

  const handleSelectOrg = (isComplete: boolean, company: EnrollmentParams) => {
    setEnrollParams(company);
    setCanNext(isComplete);
  };

  const doEnroll = async () => {
    if (api) {
      console.log({ enrollParams });
      const apiResult = await api.enroll(enrollParams);
      if (apiResult.hasError) {
        setError(apiResult.messages?.join(', '));
      } else {
        setStep(enrollParams.id ? Step.Pending : Step.PendingCompany);
        setCanNext(false);
        setError(undefined);
      }
    }
  };

  const renderStep = (): React.ReactNode => {
    switch (step) {
      case Step.Init:
        return <Loader />;
      case Step.Form:
        return <EnrollmentAddToOrg stepComplete={handleSelectOrg} />;
      case Step.Pending:
        return (
          <StatusContent
            title={'Application sent for approval.'}
            message={
              'Your application has been sent to your company guardian for approval. You will receive an email when it is approved or denied.'
            }
          />
        );
      case Step.PendingCompany:
        return (
          <StatusContent
            title={'Application sent for approval.'}
            message={
              'Your company application has been sent to CDSA for approval. You will receive an email when it is approved or denied.'
            }
          />
        );
      case Step.UserDenied:
        return (
          <StatusContent
            title={'Your application has been denied.'}
            message={`Your application to join ${user?.associatedcompany.name} has been denied. Please contact your company administrator for help.`}
          />
        );
      case Step.CompanyDenied:
        return (
          <StatusContent
            title={'Your application has been denied.'}
            message={`Your application to create ${user?.associatedcompany.name} has been denied. Please contact your company administrator for help.`}
          />
        );
    }
  };

  const handleNextStepClick = () => {
    switch (step) {
      case Step.Form:
        doEnroll();
        break;
    }
  };

  return (
    <div className="constrained-width">
      <h1 className="h5 l-mb--2 text--gray">Step {step === Step.Form ? 1 : 2}</h1>
      {renderStep()}
      {error && <p className="error error--visible">{error}</p>}
      <div className="l-my--3">
        {step === Step.Form && (
          <Flex justify="space-between">
            <Button
              className="l-mr--1"
              variant="outline"
              onClick={() => logout({ returnTo: process.env.LOGOUT_CALLBACK_URL })}
            >
              Login as different user
            </Button>
            <Button disabled={!canNext} onClick={handleNextStepClick}>
              Next
            </Button>
          </Flex>
        )}
        {(step === Step.Pending || step === Step.PendingCompany) && (
          <>
            <Button onClick={() => navigate('/')}>Back to CDSA home</Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Enrollment;

import React, { useEffect, useState, createContext, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from '../contexts/ApiProvider';
import { Contact } from '../services/Api';

type UserContextTypes = {
  isAuthenticated: boolean;
  isEnrolled: boolean;
  isLoading: boolean;
  user?: Contact | null;
  authUser?: any | null;
};

const UserContext = createContext<UserContextTypes>({
  isAuthenticated: false,
  isEnrolled: false,
  isLoading: true,
  user: null,
  authUser: null,
});

const useUserContext = () => useContext(UserContext);

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<Contact>();
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const { api } = useApi();

  const { isLoading: authLoading, isAuthenticated, user: authUser } = useAuth0();

  useEffect(() => {
    if (authLoading) return;

    if (isAuthenticated) {
      if (!currentUser && api) {
        const getUser = async () => {
          const apiResult = await api.currentContact();
          if (!apiResult.hasError && apiResult.record) {
            setCurrentUser(apiResult.record);
            setIsEnrolled(apiResult.record.portalApproved === 'approved');
          }
          setUserLoading(false);
        };

        getUser();
      } else {
        setUserLoading(false);
      }
    } else {
      setUserLoading(false);
    }
  }, [isAuthenticated, setCurrentUser, authLoading, api, setUserLoading, currentUser]);

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        isEnrolled,
        isLoading: authLoading || userLoading,
        user: currentUser,
        authUser: authUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { useUserContext };
export default UserContextProvider;

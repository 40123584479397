import React from 'react';
import Flex from './../components/layout/Flex';
import FlexColumn from './../components/layout/FlexColumn';

type PageHeaderProps = {
  title?: string;
};

const PageHeader = ({ title }: PageHeaderProps) => {
  return (
    <section className="l-pl--1">
      <Flex>
        <FlexColumn>
          <h1 className="h3 l-p--2">{title || ''}</h1>
        </FlexColumn>
        <FlexColumn>
          <span>gear</span>
        </FlexColumn>
      </Flex>
    </section>
  );
};

export default PageHeader;

import React, { useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserContext } from '../contexts/UserContextProvider';

const Logout = () => {
  const { logout } = useAuth0();
  const { isAuthenticated, isLoading } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      logout({ returnTo: process.env.LOGOUT_CALLBACK_URL });
      navigate('/login');
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return <Loader />;
  }
  return <Navigate to="/login" />;
};

export default Logout;

import React, { useEffect, useState } from 'react';
import Loader from '../components/Loader';
import CompanyProfile from '../components/CompanyProfile';
import { Company } from '../services/Api';
import { useApi } from '../contexts/ApiProvider';
import { useParams } from 'react-router-dom';

const CompanyView = () => {
  const [company, setCompany] = useState<Company>();
  const [error, setError] = useState<string>();
  const { id } = useParams();

  const { api } = useApi();

  useEffect(() => {
    if (id && api) {
      const getCompany = async () => {
        const apiResult = await api.company(id);
        if (apiResult.hasError) {
          setError(apiResult.messages?.join(', '));
        } else {
          setCompany(apiResult.record);
        }
      };
      getCompany();
    }
  }, [id, setCompany, api]);

  if (!company) {
    return <Loader />;
  }

  return (
    <>
      {error && <p className="error error--visible">{error}</p>}
      <CompanyProfile company={company} />
    </>
  );
};

export default CompanyView;

import React from 'react';

const Audits = () => {
  return (
    <div>
      <h1>Audits</h1>
      <p>
        Sed dictum diam magna, at feugiat mi interdum eu. Maecenas efficitur nunc lorem, sit amet consectetur enim
        venenatis sit amet. Donec quis neque eu orci sagittis commodo a sit amet felis. Vestibulum fermentum porta
        sodales. Duis dapibus tortor nisl, at molestie justo ultricies id. Aliquam mollis lobortis purus, a fermentum ex
        placerat eget. Phasellus nec rutrum massa. Orci varius natoque penatibus et magnis dis parturient montes,
        nascetur ridiculus mus. Nam tincidunt leo nec eros tempus dapibus. Suspendisse cursus orci quis orci mollis
        accumsan.
      </p>
    </div>
  );
};

export default Audits;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div>
      <h1 className="h3 l-mb--1">Sorry about that</h1>
      <p className="l-mb--3">We couldn&#39;t find what you were looking for.</p>
      <Button onClick={() => navigate(-1)}>Go Back</Button>
    </div>
  );
};

export default NotFound;

import React from 'react';
import styles from '../styles/components/Table.module.scss';

const Table = ({ columns, data }: { columns: any; data: any }): JSX.Element => {
  const numOfColumns = columns.length;
  const columnWidth = { '--width': `${100 / numOfColumns}%` } as React.CSSProperties;

  const renderTableHeaders = () => {
    return columns.map((column: any, i: number) => (
      <th key={`header-${i}`} style={columnWidth} data-cols={numOfColumns} className={styles['table-column']}>
        {column.Header}
      </th>
    ));
  };

  const renderTableRows = () => {
    return data.map((row: any, i: number) => {
      return (
        <tr key={`row-${i}`} className={styles['table-row']}>
          {Object.keys(row).map((cell: any, j: number) => {
            return (
              <td key={`column-${j}`} style={columnWidth} className={styles['table-column']}>
                {row[cell]}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <table className={styles['table']}>
      <thead className={styles['table-header']}>{renderTableHeaders()}</thead>
      <tbody className={styles['table-body']}>{renderTableRows()}</tbody>
    </table>
  );
};

export default Table;
